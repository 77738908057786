@import "./themes";
@import "./mixins";

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }
}
.mlr-10 {
  margin: 0px 10px;
}

.e-rowcell {
  color: #878787 !important;
  font-size: 11pt;
}

vta-employee-list {
  .e-grid .e-pagercontainer {
    display: flex;
    justify-content: center;

    .e-numericcontainer {
      display: flex;
      align-items: center;
      margin-top: 5px;
    }

    & > * {
      display: flex;
      align-items: stretch !important;
      margin-top: 5px !important;

      a {
        border-radius: 50% !important;
        line-height: unset !important;
        width: 2rem !important;
      }
    }
  }
}

nb-layout-column {
  @include respond(tab-port) {
    padding: 0 !important;
  }

  nb-card-body {
    @include respond(tab-port) {
      padding: 0 !important;
    }
  }
}

nb-auth {
  nb-card {
    border: none !important;
  }

  nb-layout-column {
    padding: 0 !important;

    nb-card-body {
      padding: 0 !important;
    }
  }
}

home {
  @include respond(tab-port) {
    .navbar {
      flex-wrap: nowrap !important;
    }

    .navbar-brand {
      img {
        width: 50%;
      }
    }

    .navbar-collapse {
      top: 65%;
    }

    .footer-area {
      margin-top: 50px !important;
    }
  }

  @include respond(phone) {
    .subscribe-area {
      padding: 30px;
    }

    .subscribe-content {
      margin-top: 0 !important;

      .subscribe-title {
        text-align: center;
      }
    }

    .subscribe-form {
      margin-top: 24px !important;
    }

    .footer-area,
    .footer-about,
    .footer-link {
      margin-top: 0 !important;
    }

    .footer-about {
      .text,
      .social {
        margin-top: 0;
        display: flex;
        justify-content: center;
      }
    }

    .link-wrapper {
      padding-right: 0 !important;
    }

    .footer-widget {
      padding: 24px !important;
      .row {
        .align-items-end {
          justify-content: center !important;
        }
      }
    }
  }
}

dashboard {
  @include respond(phone) {
    .card-title {
      font-size: 1.5rem !important;
    }

    .control_wrapper {
      width: 100vw;
      overflow-x: auto;
    }

    #overviewgrid .e-gridpager {
      width: 100vw;
    }
  }
}

organization {
  @include respond(tab-land) {
    .row,
    [class*="col-"] {
      margin: 0 !important;
      padding: 0 !important;
    }

    .contactDetails {
      p {
        img {
          height: 1.25rem;
          width: auto;
        }
      }
    }

    .first-row,
    .second-row,
    .third-row {
      .topCard {
        margin-bottom: 0 !important;
      }

      .organization,
      .time-off,
      .timeoff-policy,
      .statutory,
      .settings,
      .department {
        margin-bottom: 1rem !important;
      }
    }

    .personalDetailcard {
      height: unset !important;

      &__header {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .title-font {
          flex: unset !important;
        }
      }

      .card {
        .card-body {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .contactDetails {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }

    .vacation-type-header {
      padding: 0.5rem !important;

      &__container {
        display: flex;
        align-items: center;
      }

      &__right {
        display: flex;
        align-items: center;

        .actionButton {
          padding: 0 !important;
        }
      }
    }

    vta-user-management {
      .e-grid {
        tbody {
          tr {
            td:first-child {
              .row {
                display: flex !important;
                align-items: center;
                flex-wrap: nowrap !important;
                gap: 1rem;

                & > div:first-child {
                  margin-left: 0 !important;
                }
              }
            }
          }
        }
      }
    }

    .e-gridpager {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .e-parentmsgbar {
      display: inline-block !important;
    }
  }

  @media (max-width: 768px) {
    .e-gridpager {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .e-pagercontainer {
      display: none !important;
    }

    .e-parentmsgbar {
      display: inline-block !important;
    }

    vta-user-management {
      .mainClass {
        height: 70vh !important;
      }

      .e-grid {
        tbody {
          tr {
            td:first-child {
              .row {
                display: flex !important;
                align-items: center;
                flex-wrap: nowrap !important;
                gap: 1rem;

                & > div:first-child {
                  margin-left: 0 !important;
                }
              }
            }
          }
        }

        .e-gridcontent {
          height: calc(70vh - 8rem);
          overflow-y: auto;
        }
      }
    }
  }
}

vta-vacation {
  @include respond(tab-port) {
    .row,
    [class*="col-"] {
      margin: 0 !important;
      padding: 0 !important;
    }

    .list-card {
      nb-card-header {
        padding: 1rem !important;
      }

      &__body {
        &--heading {
          padding: 1rem !important;
          margin-bottom: 0 !important;
        }

        .vacation-types {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          gap: 0.75rem !important;
          padding: 0 1rem !important;

          .col-md-2 {
            width: auto !important;
            max-width: 10px !important;
          }
        }
      }
    }

    .buttonbox {
      width: 100% !important;
    }
  }

  @include respond(phone) {
    .e-schedule {
      height: 36.875rem !important;
    }

    .e-schedule-toolbar {
      min-height: 6.25rem !important;
    }

    .e-table-wrap {
      &.e-month-view {
        overflow-x: auto !important;
        width: 100vw;

        .e-outer-table {
          width: 175vw !important;
        }
      }
    }

    .org-dropdown,
    .emp-dropdown {
      width: 9.375rem !important;
      top: 3.5rem !important;
    }

    .org-dropdown {
      left: 1rem !important;
    }

    .emp-dropdown {
      left: unset !important;
      right: 1rem !important;
    }
  }
}

@include respond(phone) {
  .e-quick-popup-wrapper {
    top: 4.375rem !important;

    .e-popup-header {
      display: flex !important;
      align-items: center !important;
    }
  }
}

vta-reports {
  @include respond(tab-port) {
    .row,
    [class*="col-"] {
      margin: 0 !important;
      padding: 0 !important;
    }

    .filter-container {
      padding: 1rem !important;
      gap: 1rem;

      .col-md-3 {
        flex: 0 0 calc(33.33333% - ((1rem * 2) / 3));
        max-width: calc(33.33333% - ((1rem * 2) / 3));
      }
    }

    .earned-toggle {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      padding: 0 1rem 0.5rem !important;
    }

    .filterLabel {
      margin-bottom: 0.25rem !important;
    }
  }

  @include respond(phone) {
    .filter-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem !important;

      .col-md-3 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }

    .timeOffReportgrid {
      overflow-x: auto;

      ejs-grid {
        min-width: 200vw !important;
      }

      .e-gridpager {
        width: 100vw;
      }
    }
  }
}

ngx-time-off-request {
  .icon-tool {
    font-size: 14px;
    cursor: pointer;
    height: 14px;
    width: 14px;
  }
}

nb-login {
  .e-float-input {
    input {
      min-height: 30px;
    }
  }
}

vta-employee-list {
  #overviewgrid {
    .e-gridpager {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .e-pagesizes {
      display: flex;
      align-items: center;
      gap: 1rem;

      .e-pagerdropdown {
        margin-top: 0 !important;
        width: 50px !important;
      }

      .e-pagerconstant {
        margin: 0 !important;
      }
    }
  }
}
